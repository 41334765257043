.background{
    background-image: url(../../assets/img/bg.webp);
}
.formName{
    font-size: 20px;
    padding: 2rem 0;
    line-height: 24px;
    font-weight: 700;
    color: '#22345b';
    text-align: center;
}
.stepName{
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: '#22345B';
}
.inputTextP{
  font-size: 14px;
    font-weight: 400;
    color: '#1f2733';
    font-family: serif;
}
.inputTextH{
    font-size: 16px;
    font-weight: 400;
    color: '#22345b';
    font-weight: 700;
}
.font{
     font-size: 17px;
    font-weight: 400;
    color: '#1f2733';
    font-family: serif;
}
.inputTextGrid{
   display: flex; 
   justify-content: flex-start;
   align-items: flex-end;
}