* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.Wrapper {
  display: flex;
  flex-direction: row;
}

.Left {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.Right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#Img_Pane {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

@media only screen and (min-width: 1200px) {
  .Left {
    flex: 2;
  }

  .Right {
    flex: 6;
  }
}

@media only screen and (max-width: 1024px) {
  .Left {
    display: none;
  }
}
