.mainscreen1{
 background-image: url(../../../src/assets/img/bg.webp);
filter: blur(8px);
  -webkit-filter: blur(8px);
}
.mainscreen{
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}
.mainscreenview{
 background-image: url(../../../src/assets/img/bg.webp);
 filter: blur(8px);
  -webkit-filter: blur(8px);
}
.background{
  filter: blur(8px);
  -webkit-filter: blur(8px);
}